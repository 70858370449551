import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { AccountService } from 'src/app/core/services/account.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-payu-redirect',
  templateUrl: './payu-redirect.component.html',
  styleUrl: './payu-redirect.component.scss'
})
export class PayuRedirectComponent implements OnInit {

  private status: string = '';
  private txnId: string = '';
  constructor(private route: ActivatedRoute, private accountService: AccountService, private http: HttpClient, private userService: UserService, private router: Router, private spinner: NgxSpinnerService) {

  }
  async ngOnInit() {
    this.spinner.show();
    this.route.queryParamMap.subscribe(params => {
      this.txnId = params.get('txnid');
      this.status = params.get('status');
    });

    
      if(this.status === 'success'){

        await lastValueFrom(this.accountService.updateNACHApplicationStatus(this.txnId, 2)).then((response) => 
        {
  
          this.PaymentUpdateEaseBuzz('paid', this.txnId).then(() => {
            console.log('Payment Update Complete');
          }).catch(error => {
            console.error('Payment Update Error:', error);
          });
        }).catch(err => {
          console.log(err);
          this.spinner.hide();
          this.router.navigateByUrl("/assist-journey/session-expired");
          return;
        });
      }
      else{
        await lastValueFrom(this.accountService.updateNACHApplicationStatus(this.txnId, 0)).then((response) => 
        {
            this.PaymentUpdateEaseBuzz('paid', this.txnId).then(() => {
              console.log('Payment Update Complete');
            }).catch(error => {
              console.error('Payment Update Error:', error);
            });
          }).catch(err => {
            console.log(err);
            this.spinner.hide();
            this.router.navigateByUrl("/assist-journey/session-expired");
            return;
          });

      }
   
  }

  async PaymentUpdateEaseBuzz(payment_status: string, MerchantId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.route.queryParams.subscribe((params: any) => {
        const baseUrl = environment.apiUrl + 'PaymentGateway/PaymentStatusReceiver';
        let obj = {
          'ResponseId': MerchantId,
          'payment_id': MerchantId,
          'payment_link_status': payment_status,
          'signature': "OK"
        };

        this.http.post(baseUrl, obj).subscribe(
          response => {
            if (response['data'] != null) {
              this.spinner.hide();
              if (response['data'].partnerCallbackUrl != null && response['data'].partnerCallbackUrl !== 'undefined' && response['data'].partnerCallbackUrl != '') {

   
                  /*** Redirect if DownPayment Payment Failed/Success for Sterling Holidays ***/
                  if(payment_status === 'unpaid'){
                    if(environment.sterlingHolidaysConsolidator.includes(MemoryStorageService.getItem("consolidatorId")))
                      {
                        const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "PaymentFailed" };
                        this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                        return;
                      }
                  }
    
                  
                  if(payment_status === 'paid'){
                    if(environment.sterlingHolidaysConsolidator.includes(MemoryStorageService.getItem("consolidatorId")))
                    {
                      const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "PaymentCompleted", mihuruTransactionId: MerchantId , amount: MemoryStorageService.getItem('loanAmount') };
                      this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                      return;
                    }
                  
                  }
                  /*******************/
                  window.location.replace("https://" + response['data'].partnerCallbackUrl);
                  
              } else {

                if(payment_status === 'unpaid')
                {
                  return this.router.navigateByUrl('/assist-journey/assist-underwrite').then(() => resolve());
                }
                else if(payment_status === 'paid'){
                  return this.router.navigateByUrl('/assist-journey/assist-success').then(() => resolve());
                }          
              }
            }
          },
          error => {
            reject(error);
          }
        );
      });
    });
  }
  
}
