export const environment = {
  production: true, 
  environmentName: 'production',
  apiUrl: 'https://tnpl.mihuru.com/api/',
  frontEndUrl: 'https://tnpl.mihuru.com/',
  PartnerId: 'INDCR00173',
  PartnerapiKey: '^9A77Bcd4UO#*mG0oI#V4a0FXKHg7la&^mrqWRLou1XulZB%0R',
 
  apiAirtableKey: 'key6f2jeRPPrMN3oF',
  apiAirtableHotel: 'https://api.airtable.com/v0/appE97jnGlembQNrW/Hotel/',
  apiAirtableFlight: 'https://api.airtable.com/v0/appE97jnGlembQNrW/Flights/',

  LogoApiUrl:'https://los.mihuru.com/api/1.1/wf/getpartnerlogo',
  LogoApiAuthKey :'d20fd1afb36903fd4030596c7c61619a',

  RazorPaySecretKey :'3UmbXmbOUHFqtpgFkfg7joyn',
  RazorPayKeyID :'rzp_test_YpeTHTaoOFlVI3',
  easeBuzzAutoPayKey: 'HGXN1WGG6H',
  easeBuzzEnvironment: 'prod',
  showeasebuzz: true,
  showPayU: true,
  mobileRegexPattern: '[5-9]{1}[0-9]{9}',
  CDN_BaseURL : 'https://mihuruendpoint.azureedge.net/websiteimages',
  disabledConsole: false,
  sterlingHolidaysUrl: "https://api.holidayzone.biz/mihuru/calback",
  sterlingHolidaysConsolidator: "recfUzc4w8nJZv6aA,recK9L0bKgQBWyrNq",
  sterlingEncryptionKey: "4a2c3e1f6d9b2c5d1f3e7a8b4c6d9a1b",
  PayUUrl: 'https://secure.payu.in/_payment',
  PayUKey: "K8Doqs"
};

