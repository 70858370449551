import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditRuleService } from 'src/app/core/services/credit-rule.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import swal from 'sweetalert2';
import { Plan } from '../../../core/models/personalInfo';
import { User } from '../../../core/models/user';
import { AccountService } from '../../../core/services/account.service';
import { SessionTimerService } from '../../../core/services/session-timer.service';
import { SignalRService } from 'src/app/core/services/signalr.service';

@Component({
  selector: 'app-documents-upload-qr-code',
  templateUrl: './documents-upload-qr-code.component.html',
  styleUrls: ['./documents-upload-qr-code.component.scss'],
})
export class DocumentsUploadQRCodeComponent implements OnInit {
  @Output() selectedPlan = new EventEmitter();

  public href: string = '';
  public travelPlan: string = '';
  FirstName: string = '';
  Mobile: string = '';
  Email: string = '';
  PAN: string = '';
  DOB: string = '';
  Address: string = '';
  DocsRequired: string = '';
  Downpayment!: number;
  EmiAmount!: number;
  partnerTransactionId: string = '';
  partnerCallbackUrl: string = '';
  Loanamount!: number;
  FirstEMIDueDate: string = '';
  NumberOfTotalEMI!: number;
  planDet!: Plan;
  showApplication: boolean = false;
  showDocsRequired: boolean = true;
  btnProceed: string = '';
  ProcessingFee!: number;
  PayToday!: number;
  hideEMI: boolean;
  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private creditRuleService: CreditRuleService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private sessionTimerService :SessionTimerService,
    private signalRService: SignalRService
  ) {
    MemoryStorageService.removeItem('user');
    MemoryStorageService.removeItem('finalplan');
    this.hideEMI = MemoryStorageService.getItem("userSource") === 'LANDING_PAGE' || MemoryStorageService.getItem("userSource") === 'LANDING_PAGE_WIDGET';
  }

  response1!: any;
  ngOnInit(): void {
    this.spinner.hide();
    MemoryStorageService.removeItem('OrderId');

    this.route.queryParams.subscribe((params) => {
      // console.log(params);
      this.travelPlan = params.travelplan;
      if(params.orderid){
        MemoryStorageService.setItem('OrderId', params.orderid)
      }
      else {
        //this.sessionTimerService.resumeTimer();
      }
      // console.log(this.travelPlan);

      this.accountService.GetTravelPlan(this.travelPlan).subscribe(
        (resp) => {
          this.spinner.hide();
          const response = resp['data'];
          console.log('GetTravelPlan');
          console.log(response);
          if (response != null) {
            if (response.status == 'EXPIRED') {
              this.showApplication = false;
              var user: User = {
                userName: response.email,
                email: response.email,
                mobileNumber: response.mobileNumber,
                token: response.token,
                showFooter: false,
              };
              this.accountService.setCurrentUser(user);
              MemoryStorageService.setItem('user', JSON.stringify(user));
              swal.fire('The Plan is expired. Please try again');
              this.router.navigateByUrl('/sorry');
            } else if (response.status == 'INVALID') {
              this.showApplication = false;
              // var user: User = {
              //   userName : response.email,
              //   email: response.email,
              //   mobileNumber: response.mobileNumber,
              //   token: response.token,
              //   showFooter:true
              // };
              // MemoryStorageService.setItem('user', JSON.stringify(user));
              swal.fire('The selected plan is not valid anymore!');
              this.router.navigateByUrl('/sorry');
            } else {
              this.showApplication = true;
              this.btnProceed =
                response.ckycPan && response.ckycAadhaar
                  ? 'Proceed'
                  : 'Upload document';
              this.FirstName = response.firstname + ' ' + response.lastName;
              this.Mobile = '+91' + response.mobileNumber;
              this.Email = response.email;
              this.PAN = response.pan;
              this.DOB = moment(response.dob).format('DD-MMM-YYYY'); //response.dob.substring(0, 10);
              this.Address = response.address;
              if(response.requiredDocuments === "Repeat"){
                this.DocsRequired = '';
                this.showDocsRequired = false;
              }
              else if (JSON.parse(response.requiredDocuments).bankStatement) {
                if (response.ckycPan && response.ckycAadhaar) {
                  this.DocsRequired = 'Bank Statement';
                } else if (response.ckycPan && !response.ckycAadhaar) {
                  this.DocsRequired = 'Aadhaar & Bank Statement';
                } else if (!response.ckycPan && response.ckycAadhaar) {
                  this.DocsRequired = 'PAN & Bank Statement';
                } else {
                  this.DocsRequired = 'PAN, Aadhaar & Bank Statement';
                }
              } else {
                if (response.ckycPan && response.ckycAadhaar) {
                  this.DocsRequired = '';
                  this.showDocsRequired = false;
                } else if (response.ckycPan && !response.ckycAadhaar) {
                  this.DocsRequired = 'Aadhaar';
                } else if (!response.ckycPan && response.ckycAadhaar) {
                  this.DocsRequired = 'PAN';
                } else {
                  this.DocsRequired = 'PAN & Aadhaar';
                }
              }
              // this.DocsRequired = JSON.parse(response.requiredDocuments)
              //   .bankStatement
              //   ? 'PAN, Aadhaar & Bank Statement'
              //   : 'PAN & Aadhaar';
              this.Downpayment = response.plan.downpayment!;
              this.EmiAmount = response.plan.emi_amount!;
              this.Loanamount = response.plan.loan_amount!;
              MemoryStorageService.setItem("loanAmount",  response.plan.loan_amount!);
              MemoryStorageService.setItem('userSource', response.userSource);
              this.hideEMI = response.userSource === 'LANDING_PAGE' || response.userSource === 'LANDING_PAGE_WIDGET';
              this.FirstEMIDueDate = moment(response.plan.emi_date).format(
                'DD-MMM-YYYY'
              ); // response.plan.emi_date!.substring(0, 10);
              this.NumberOfTotalEMI = response.plan.tenor!;
              this.ProcessingFee =
                response.plan.processing_fee! + response.plan.gst!;
              this.PayToday = this.Downpayment + this.ProcessingFee;
              this.FirstEMIDueDate = this.FirstEMIDueDate;
              this.partnerTransactionId = response.partnerTransactionId;
              MemoryStorageService.setItem("partnerTransactionId",  this.partnerTransactionId);
              this.partnerCallbackUrl = response.partnerCallbackUrl;
              var user: User = {
                userName: response.email,
                email: response.email,
                mobileNumber: response.mobileNumber,
                token: response.token,
                docs: [
                  {
                    bankstatement: response.requiredDocuments === "Repeat" ? false : (JSON.parse(response.requiredDocuments)
                      .bankStatement
                      ? true
                      : false),
                  },
                ],
                emiamount: Number(this.EmiAmount),
                collectionAmount: Number(this.Loanamount),
                fullname: response.firstname + ' ' + response.lastName,
                firstCollectionDate: this.FirstEMIDueDate,
                showFooter: true,
              };
              this.accountService.setCurrentUser(user);
              this.signalRService.initSignalR(user.token);
              MemoryStorageService.setItem('user', JSON.stringify(user));
              MemoryStorageService.setItem('finalplan', JSON.stringify(response.plan));
              localStorage.setItem('partnercallback',this.partnerCallbackUrl);
            }
          }
        },
        (error) => {
          this.spinner.hide();
          if (error.errors != null || error.errors != undefined) {
            swal.fire(error.errors);
          }
        }
      );
    });
  }

  ProceedToDocUpload() {
    this.router.navigateByUrl('/assist-journey/identity-proof');
  }

  notifyHost() {
    window.opener.postMessage('Message from new window');
  }
}
