import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { AccountService } from 'src/app/core/services/account.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import swal from 'sweetalert2';

export interface DocumentFile {
  professionIdtype: string,
  file: string,
  type: string
}

interface ServiceDetails {
  stateCode: string;
  docNumber: string;
  customerId: string;
  accountNo: string;
  divisionId: string;
  typeId: string;
  serviceNumber1: string;
  serviceNumber2: string;
  serviceNumber3: string;
  installationNumber: string;
}

@Component({
  selector: 'app-assist-utility',
  templateUrl: './assist-utility.component.html',
  styleUrl: './assist-utility.component.scss',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }), 
        animate('500ms 0s ease-in', style({ opacity: 1 })) 
      ])
    ]),
    
  ]
})


export class AssistUtilityComponent {
  @ViewChild('utilitydoc') utilitydoc: ElementRef;
  residenceTypes = [ 'Owned', 'Rented'];
  documentOwnedTypes = [ 'Landline Bill', 'Broadband Bill', 'Electricity Bill', 'Gas Pipeline Bill', 'Water Bill', 'LPG Passbook' ];
  documentRentedTypes = [ 'Landline Bill', 'Broadband Bill', 'LPG Passbook' ];
  BoardsList = [{ name: 'Maharashtra (All)', value: 13 }, {  name: 'Maharashtra (BEST/TATA)', value: 14 }, {  name: 'Maharashtra (Adani)', value: 15 }, {  name: 'Odisha (Central)', value: 40 }, {  name: 'Odisha (other)', value: 41 }, {  name: 'Kerala', value: 33 }, {  name: 'Other', value: 1000 }];
  model: FormGroup;
  UploaderLabelName: string = '';
  fileSelected: boolean =  false;
  filesData: DocumentFile[] = [
    { professionIdtype: '', file: '', type: '' }, 
  ]

  ShowVerifyOption = true;
  tried = 0;

  serviceDetails: ServiceDetails = {
    stateCode: '',
    docNumber: '',
    customerId: '',
    accountNo: '',
    divisionId: '',
    typeId: '',
    serviceNumber1: '',
    serviceNumber2: '',
    serviceNumber3: '',
    installationNumber: '',
  };

  constructor(private fb: FormBuilder, private accountService: AccountService, private spinner: NgxSpinnerService, private router: Router){
    this.spinner.hide();
    this.model = this.fb.group({
      residenceType: 'Please residence type',
      documentType: 'Please document type',
      BoardType: '1001',
      binder: '',
      accountNumber: '',
      SDOCode: '',
      ConsumerNo: '',
    });

    this.model.get('residenceType')?.valueChanges.subscribe((value) => {
      this.fileSelected = false;
      this.model.get('documentType').setValue('Please document type'); 
    });

    this.model.get('documentType')?.valueChanges.subscribe((value) => {
      this.fileSelected = false;
      if (this.utilitydoc) {
        this.utilitydoc.nativeElement.value = '';
      }
    });

    this.model.get('BoardType')?.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }

  private getFileExtension(fileName: string): string {
    const fileParts = fileName.split('.');
    return fileParts.length > 1 ? fileParts.pop()! : '';
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.fileSelected = true;
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileBase64 = reader.result as string;
        this.filesData[0] = { professionIdtype: this.model.get('documentType')?.value, type: this.getFileExtension(file.name), file: fileBase64.split('base64,')[1] }
      };
      reader.readAsDataURL(file);
    }

  }

  allowOnly(event: KeyboardEvent, type: string): void {
    const input = event.target as HTMLInputElement;
    let allowedPattern;
  
    if (type === 'numbers') {
      allowedPattern = /[^0-9]/g; // Match anything that's not a number
    } else if (type === 'alphabets') {
      allowedPattern = /[^a-zA-Z\s']/g; // Match anything that's not a letter
    }
  
    // Use input event to handle changes
    input.addEventListener('input', (e: Event) => {
      const currentValue = input.value;
      input.value = currentValue.replace(allowedPattern, '');
    });
  }


  async submit(){
    this.spinner.show();

    const boardCode = this.model.get('BoardType')?.value;
    if( boardCode !== '1000' && boardCode !== '1001'){
      this.serviceDetails.stateCode = boardCode;
      this.serviceDetails.docNumber = this.model.get('ConsumerNo')?.value;
      //this.serviceDetails.customerId = this.model.get('accountNumber')?.value;
      this.serviceDetails.serviceNumber1 = this.model.get('sdocode')?.value;
      this.serviceDetails.serviceNumber2 = this.model.get('SDOCode')?.value;
      this.serviceDetails.serviceNumber3 = this.model.get('accountNumber')?.value;

      try{
      var res = await lastValueFrom(this.accountService.VerifyElectricityBill(this.serviceDetails));
      
      if(res['data'] !== null){
        if(res['data'] === 0){
          this.spinner.hide();
          swal.fire({
            text: "No details found for the given bill details",
            confirmButtonText: 'OK'
          }).then((result) => {
            if(this.tried > 2){
              this.model.reset();
              this.ShowVerifyOption = false;
              return;
            }

            this.tried += 1;
            return;
          }); 
        }
        else {
          swal.fire({
            text: "Verified Successfully",
            confirmButtonText: 'OK'
          }).then((result) => {
            lastValueFrom(this.accountService.UpdateApplicationReason(MemoryStorageService.getItem("currentStatus"), 'Utility Bill Uploaded')).then( res => {
              this.spinner.hide();
              return this.router.navigateByUrl('/assist-journey/assist-income-proof');
            });
          }); 
        }
        this.spinner.hide();
        return;
      }
      }catch(e){

        if(e.error['errors']['message']  === 'Internal server error.'){
          return this.router.navigateByUrl('/assist-journey/session-expired');
        }

        swal.fire({
          text: "Unable to Verify",
          confirmButtonText: 'OK'
        }).then((result) => {

            if(this.tried > 2){
              this.model.reset();
              this.ShowVerifyOption = false;
              return;
            }

            this.tried += 1;
        }); 
        this.spinner.hide();
      }

      return;
    }


    let objupload = {
      "occupationType": MemoryStorageService.getItem("professionType"),
      "uploadFiles": this.filesData
    };
    await this.accountService.uploadBusinessDocuments(objupload).subscribe(res => {
      if(res['data']){
        lastValueFrom(this.accountService.UpdateApplicationReason(MemoryStorageService.getItem("currentStatus"), 'Utility Bill Uploaded')).then( res => {
          this.spinner.hide();
          return this.router.navigateByUrl('/assist-journey/assist-income-proof');
        });
        
      }
      else {
        swal.fire({
          text: "Unable to Upload",
          confirmButtonText: 'OK'
        }).then((result) => {
            return;
        }); 
        this.spinner.hide(); 
        }
    });
  }
}
