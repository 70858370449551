import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AadhaarVerificationComponent } from './aadhaar-verification/aadhaar-verification.component';
import { AddressProofComponent } from './address-proof/address-proof.component';
import { AssistConfirmPersonalDetailsComponent } from './assist-confirm-personal-details/assist-confirm-personal-details.component';
import { AssistLoanAgreementComponent } from './assist-loan-agreement/assist-loan-agreement.component';
import { AssistPaymentPlanComponent } from './assist-payment-plan/assist-payment-plan.component';
import { AssistProcessingComponent } from './assist-processing/assist-processing.component';
import { AssistShowPaymentPlansComponent } from './assist-show-payment-plans/assist-show-payment-plans.component';
import { AssistSignUpComponent } from './assist-sign-up/assist-sign-up.component';
import { AssistSubmitOtpComponent } from './assist-submit-otp/assist-submit-otp.component';
import { AssistSuccessComponent } from './assist-success/assist-success.component';
import { AssistTeamCallbackComponent } from './assist-team-callback/assist-team-callback.component';
import { AssistUnderwriteComponent } from './assist-underwrite/assist-underwrite.component';
import { BankStatementComponent } from './bank-statement/bank-statement.component';
import { DocumentsUploadQRCodeComponent } from './documents-upload-qr-code/documents-upload-qr-code.component';
import { IdentityProofComponent } from './identity-proof/identity-proof.component';
import { NachMandateComponent } from './nach-mandate/nach-mandate.component';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { ProcessOfflineComponent } from './process-offline/process-offline.component';
import { ProfessionalDetailsComponent } from './professional-details/professional-details.component';
import { SalaryUploadComponent } from './salary-upload/salary-upload.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { SorryComponent } from './sorry/sorry.component';
import { AssistUtilityComponent } from './assist-utility/assist-utility.component';
import { AssistIncomeProofComponent } from './assist-income-proof/assist-income-proof.component';
import { PayuRedirectComponent } from './payu-redirect/payu-redirect.component';


const routes: Routes = [
      { path: '', component: AssistSignUpComponent },
      { path: 'sign-up', component: AssistSignUpComponent },
      { path: 'submit-otp', component: AssistSubmitOtpComponent },
      { path: 'confirm-details', component: AssistConfirmPersonalDetailsComponent },
      { path: 'payment-plans', component: AssistShowPaymentPlansComponent },
      { path: 'identity-proof', component: IdentityProofComponent },
      { path: 'address-proof', component: AddressProofComponent },
      { path: 'aadhaar-verification', component: AadhaarVerificationComponent },
      { path: 'bank-statement', component: BankStatementComponent },
      { path: 'nach-mandate', component: NachMandateComponent },
      { path: 'docupload', component: DocumentsUploadQRCodeComponent },
      { path: 'assist-payment-plan', component: AssistPaymentPlanComponent },
      { path: 'payment-gateway', component: PaymentGatewayComponent },
      { path: 'professional-details', component: ProfessionalDetailsComponent },
      { path: 'session-expired', component: SessionExpiredComponent },
      { path: 'process-offline', component: ProcessOfflineComponent },
      { path: 'salary-upload', component: SalaryUploadComponent },
      { path: 'assist-success', component: AssistSuccessComponent },
      { path: 'sorry', component: SorryComponent},
      { path: 'team-callback', component: AssistTeamCallbackComponent },
      { path: 'loan-agreement', component: AssistLoanAgreementComponent },
      { path: 'assist-underwrite', component: AssistUnderwriteComponent},
      { path: 'assist-processing', component: AssistProcessingComponent},
      { path: 'assist-current-address-proof', component: AssistUtilityComponent},
      { path: 'assist-income-proof', component: AssistIncomeProofComponent },
      { path: 'payu-redirect', component: PayuRedirectComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AssistJourneyRoutingModule { }
