<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center mt-4">
            <div class="col-8 blue_box p-4 text-center">
                <h3 class="heading">Current Address Proof</h3>
            </div>
        </div>
        <div class="row justify-content-center mt-4">
            <div class="col-11 white_box p-4">
                <form [formGroup]="model" novalidate autocomplete="off" (ngSubmit)="submit()">
                <div class="row justify-content-center">
                    <div class="col-sm-10 col-12">
                        <ng-container>
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label class="control-label">Residence type</label>
                                        <select formControlName="residenceType" class="form-select"
                                            style="margin-top: .3rem;">
                                            <option value="Please residence type" selected disabled>Please residence type</option>
                                            <option *ngFor="let type of residenceTypes" [value] = "type">{{
                                                type }}</option>
                                        </select>
                                        <!-- <span *ngIf="model.get('companyType')?.invalid && model.get('companyType')?.touched" class="alert alert-danger mt-2">
                        Company Type is mandatory
                      </span> -->
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6" *ngIf="model.get('residenceType')?.value == 'Owned'" @fadeInOut>
                                    <div class="form-group">
                                        <label class="control-label">Document Type</label>
                                        <select formControlName="documentType" class="form-select"
                                            style="margin-top: .3rem;">
                                            <option value="Please document type" selected disabled>Please document type</option>
                                            <option *ngFor="let type of documentOwnedTypes" [value] = "type">{{
                                                type }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6" *ngIf="model.get('residenceType')?.value == 'Rented'" @fadeInOut>
                                    <div class="form-group">
                                        <label class="control-label">Document Type</label>
                                        <select formControlName="documentType" class="form-select"
                                            style="margin-top: .3rem;">
                                            <option value="Please document type" selected disabled>Please document type</option>
                                            <option *ngFor="let type of documentRentedTypes" [value] = "type">{{
                                                type }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="documentRentedTypes.includes(model.get('documentType')?.value) || documentOwnedTypes.includes(model.get('documentType')?.value)" @fadeInOut>
                                    <div *ngIf="model.get('documentType')?.value == 'Electricity Bill' && ShowVerifyOption">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label class="control-label">Select Electricity Board</label><span style="color:red">*</span>
                                                    <select formControlName="BoardType" class="form-select"
                                                        style="margin-top: .3rem;">
                                                        <option value="1001" selected disabled>Please Electricity Board</option>
                                                        <option *ngFor="let type of BoardsList" [value] = "type.value">{{
                                                            type.name }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <!-- All States -->
                                             <!-- (['40', '41'].indexOf(model.get('BoardType')?.value) === -1) -->
                                            <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="(['41'].indexOf(model.get('BoardType')?.value) === -1) && model.get('BoardType')?.value !== '1000'">
                                                <div class="form-group">
                                                    <label class="control-label" >Consumer No</label><span style="color:red">*</span>
                                                    <input type="text" formControlName="ConsumerNo" (input)="allowOnly($event,'numbers')" class="form-control" style="margin-top: .3rem;">
                                                </div>
                                            </div>
                                            <!-- --------------------------- -->


                                            <!-- odisha (Central) - pending - 40 -->
                                            <!-- <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="model.get('BoardType')?.value === '40'">
                                                <div class="form-group">
                                                    <label class="control-label" >Consumer No</label><span style="color:red">*</span>
                                                    <input type="text" class="form-control" style="margin-top: .3rem;">
                                                </div>
                                            </div>  -->
                                            <!-- ----------------------------------------------------------------- -->


                                            <!-- odisha (other) - 41 -->
                                            <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="model.get('BoardType')?.value === '41'">
                                                <div class="form-group">
                                                    <label class="control-label" >SDO Code</label><span style="color:red">*</span>
                                                    <input type="text" class="form-control" (input)="allowOnly($event,'numbers')" formControlName="SDOCode" style="margin-top: .3rem;">
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="model.get('BoardType')?.value === '41'">
                                                <div class="form-group">
                                                    <label class="control-label" >Binder</label><span style="color:red">*</span>
                                                    <input type="text" formControlName="binder" (input)="allowOnly($event,'numbers')" class="form-control" style="margin-top: .3rem;">
                                                </div>
                                            </div>
    

                                            <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="model.get('BoardType')?.value === '41'">
                                                <div class="form-group">
                                                    <label class="control-label" >Account No</label><span style="color:red">*</span>
                                                    <input type="text" class="form-control" (input)="allowOnly($event,'numbers')" formControlName="accountNumber" style="margin-top: .3rem;">
                                                </div>
                                            </div>

                                            <!-- --------------------------- -->

                                            
                                            <!-- odisha (kerala) - Pending - 33 -->
                                            <!-- <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="model.get('BoardType')?.value === '33'">
                                                <div class="form-group">
                                                    <label class="control-label" >Consumer No</label><span style="color:red">*</span>
                                                    <input type="text" class="form-control" style="margin-top: .3rem;">
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div *ngIf="model.get('documentType')?.value !== 'Electricity Bill' || model.get('BoardType')?.value === '1000' || !ShowVerifyOption">
                                        <div class="form-group">
                                            <div class="mt-3">
                                            <label class="control-label">{{model.get('documentType')?.value}} </label><span style="color:red">*</span>
                                            <input #utilitydoc class="form-control" type="file" accept=".pdf, .jpg, .jpeg, .png" id="currentAddressProod" (change)="onFileSelected($event)" text="Choose File" style="margin-top: .8rem"/>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <!-- <div class="col-12" *ngIf="documentRentedTypes.includes(model.get('documentType')?.value) || documentOwnedTypes.includes(model.get('documentType')?.value)" @fadeInOut>
                                    <div class="form-group">
                                        <div class="mt-3">
                                          <label class="control-label">{{model.get('documentType')?.value}} </label><span style="color:red">*</span>
                                          <input #utilitydoc class="form-control" type="file" accept=".pdf, .jpg, .jpeg, .png" id="currentAddressProod" (change)="onFileSelected($event)" text="Choose File" style="margin-top: .8rem"/>
                                        </div>
                                    </div>
                                </div>  -->
                            </div>
                            
                            <div class="row mt-4" *ngIf="model.get('documentType')?.value !== 'Electricity Bill' || model.get('BoardType')?.value === '1000' || !ShowVerifyOption " @fadeInOut>
                                <div class="col-12 text-center" >
                                    <button type="submit" class="btn btn-warning" [attr.disabled] = "!fileSelected ? true : null" >
                                        Upload
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-4" *ngIf="model.get('documentType')?.value === 'Electricity Bill' && model.get('BoardType')?.value !== '1000' && ShowVerifyOption" @fadeInOut>
                                <div class="col-12 text-center" >
                                    <button type="submit" class="btn btn-warning" [attr.disabled] = "((model.get('BoardType')?.value === '41' && (model.get('accountNumber')?.value.length < 3 || model.get('binder')?.value.length < 3 || model.get('SDOCode')?.value.length < 3)) || ( ['41'].indexOf(model.get('BoardType')?.value) === -1) && model.get('ConsumerNo')?.value.length < 3 ) ? true : null" >
                                        Verify
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="col-lg-5 col-11">
    <app-assist-left></app-assist-left>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>