<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center">
            <div class="col-8 blue_box p-4 text-center">
                <h3 class="heading">Session Expired</h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-11 text-center white_box p-4">
               <img src="../../assets/images/assist-time-limit.png" class="img mt-4" />
                        <h4 class="mt-4">Your session has expired.</h4>
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-11">
        <app-assist-left></app-assist-left>
    </div>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>