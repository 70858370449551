import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MemoryStorageService } from './core/services/memory-storage.service';
import swal from 'sweetalert2';
import { SignalRService } from './core/services/signalr.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  hideFooter: boolean = false;
  hideHeader: boolean = false;

  constructor(private route: ActivatedRoute, private signalRService: SignalRService, private router: Router) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.hideFooter = params['hidefooter'] === 'true';
      this.hideHeader = params['hideheader'] === 'true';
    });
    window.addEventListener('beforeunload', this.clearStorage);

    this.signalRService.sessionInvalid$.subscribe((message: string) => {
      if (message) {
        swal.fire(message);
        localStorage.clear();
        MemoryStorageService.clear();
        return this.router.navigateByUrl('/assist-journey/session-expired');
      }
    });

  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.clearStorage);
  }

  private clearStorage() {
    if(environment.production){
      MemoryStorageService.clear();
    }
  }
}
