<div class="row justify-content-center mt-3  d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <!--  -->
        <div class="row justify-content-center mt-4">
            <div class="col-8 blue_box p-4 text-center heading">
                <ng-content select=".heading">Salary Slip Upload</ng-content>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-11 white_box p-4">
                <div class="row">
                    <div class="col-12">
                        <ng-content select=".heading1"></ng-content>
                        <div class="file-upload">
                            <div class="image-upload-wrap">
                                <div #filedrop ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (click)="file.click()" (fileOver)="fileOverBase($event)" [uploader]="uploader" class="upload-drop-zone">
                                    <div class="drag-text" *ngIf="uploader.queue.length === 0">
                                        <h3><i class="fas fa-upload"></i><br />
                                            Drag & drop or select file<br />
                                            <div class="choose_file"> Choose file </div>
                                        </h3>
                                    </div>
                                    <div *ngIf="uploader.queue.length > 0" style="text-align: center;width: 100%;margin: auto;">
                                        <div style="line-height: 150%;padding: 55px 10px;margin: 0px;">
                                            <ul>
                                                <li *ngFor="let file of uploader.queue">{{ file.file.name }}   <button type="button" class="remove-btn" (click)="removeFile(file, $event)">
                                                    <i class="fas fa-trash-alt"></i>
                                                  </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <input #file style="display: none;" type="file" (change)="onFileSelected()" ng2FileSelect [uploader]="uploader" multiple/>
                                </div>
                            </div>
                        </div>
                        <ng-content select=".uploaddocfooter"></ng-content>
                    </div>
                </div>
                <div class="row justify-content-center text-center buttonDiv">
                    <div class="col-12">
                        <button type="button" class="btn btn-warning mt-3" (click)="upload()" *ngIf="uploader.queue.length > 0"> Next </button>
                    </div>
                </div>
                <ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>
            </div>
        </div>
        
        <!--  -->
    </div>
    <div class="col-lg-5 col-11">
        <app-assist-left></app-assist-left>
    </div>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>