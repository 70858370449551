import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection!: HubConnection;
  private sessionInvalidSubject = new BehaviorSubject<string>(null);

  sessionInvalid$ = this.sessionInvalidSubject.asObservable();

  constructor() {
  }

  public initSignalR(token: string){

    if (this.isConnected()) {
        console.log('SignalR already connected.');
        return;
    }

    const hubUrl = environment.apiUrl + 'sessionHub?access_token=' + encodeURIComponent(token);

    this.hubConnection = new HubConnectionBuilder()
        .withUrl(hubUrl)  // Use the URL with the token query parameter
        .withAutomaticReconnect()
        .build();

    this.hubConnection.onreconnecting(error => {
        console.warn('SignalR reconnecting...', error);
        });
    
        this.hubConnection.onreconnected(connectionId => {
        console.log('SignalR reconnected successfully. ConnectionId:', connectionId);
        });
    
        this.hubConnection.onclose(error => {
        console.error('SignalR connection closed. Attempting to reconnect...', error);
        this.startConnection(); // Reconnect manually if automatic reconnect fails
        });

    this.startConnection();

    // Listen for events from the server (e.g., session invalidation)
    this.hubConnection.on('SessionInvalid', (message: string) => {
        this.handleSessionInvalid(message);
    });
}

public isConnected(): boolean {
    return this.hubConnection && this.hubConnection.state === HubConnectionState.Connected;
  }

private startConnection(): void {
  this.hubConnection
    .start()
    .then(() => console.log('SignalR connected'))
    .catch((err) => console.log('Error while starting SignalR connection: ' + err));
}

  private handleSessionInvalid(message: string): void {
    this.sessionInvalidSubject.next(message);
  }
}
