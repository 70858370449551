import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/core/models/user';
import { AccountService } from 'src/app/core/services/account.service';
import { CreditRuleService } from 'src/app/core/services/credit-rule.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import swal from 'sweetalert2';
import { SessionTimerService } from '../../../core/services/session-timer.service';

@Component({
  selector: 'app-assist-personal-details',
  templateUrl: './assist-personal-details.component.html',
  styleUrls: ['./assist-personal-details.component.scss']
})
export class AssistPersonalDetailsComponent implements OnInit {
  @Input() FirstName!: string;
  @Input() Mobile!: string;
  @Input() Email!: string;
  @Input() PAN!: string;
  @Input() DOB!: string;
  @Input() Address!: string;
  @Input() DocsRequired!: string;
  @Input() btnProceedText!: string;

  hideChangePlanButton: Boolean = false;
  constructor(
    private router: Router,
    private creditRuleService: CreditRuleService,
    private spinner: NgxSpinnerService,
    private accountService: AccountService,
    private sessionTimerService :SessionTimerService
  ) {
    this.hideChangePlanButton = MemoryStorageService.getItem("userSource") === 'LANDING_PAGE' || MemoryStorageService.getItem("userSource") === 'LANDING_PAGE_WIDGET';
   }

  ngOnInit(): void {
  }
  ProceedToDocUpload() {
    this.spinner.show();
    this.router.navigateByUrl('/assist-journey/identity-proof');
  }

  async OpenCreditPDF()
  {
    this.spinner.show();
    try{
      const response = await this.creditRuleService.GetCreditReportPDF().toPromise()
      if(response && response['data'] ){
          //const pdfUrl = 'data:application/pdf;base64,' + response['data'];

          const base64Data = response['data'];
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);

          // Open PDF in a new tab
          //window.open(blobUrl, '_blank');

          const newTab = window.open();
          if (newTab) {
            newTab.document.write(`<iframe src="${blobUrl}" frameborder="0" style="width:100%;height:100%;"></iframe>`);
            newTab.document.close();
          } else {
            console.error('Failed to open new tab. It might be blocked by your browser.');
            swal.fire('An error occurred, Please try again!');
          }
      }
      else{
        swal.fire('An error occurred, Please try again!');
      }
    }catch(err){
      console.log(err);
    }
    this.spinner.hide();
  }

  async ChangePlan()
  {
    try
      {
        this.spinner.show();
        const response = await this.accountService.GenerateChangedPlan().toPromise()
        if(response) 
        {
          
        console.log(response);
        if(response['data']==null)
          {
            this.spinner.hide();
            this.router.navigateByUrl('/assist-journey/sorry');
          }
        MemoryStorageService.setItem(
          'assistTravelPlans',
          JSON.stringify(response['data']['plan_details'])
        );

        // Setting SessionId to be used on Session Expire Page for updating the apllication Status 
        const user: User = { token:MemoryStorageService.getItem('customerToken'), userName: '', email: '', mobileNumber: ''}
        this.accountService.setCurrentUser(user);
        //////

        //Setting if Available Limit has exhausted 
        const travelcost = parseInt(MemoryStorageService.getItem('TravelCost'));
        const availableLimit = parseInt(response['data']['availableLimit']);

        // MemoryStorageService.setItem('hasLimitExhausted', travelcost > availableLimit ? "true": "false")
        /////

        // this.spinner.hide();
        // this.router.navigateByUrl('/assist-journey/payment-plans', {
        //   state: {
        //     assistTravelPlans: JSON.stringify(
        //       response['data']['plan_details']
        //     ),
        //   },
        // });

        MemoryStorageService.setItem('assistTravelPlans',JSON.stringify(response['data']['plan_details']));
        this.spinner.hide();


        this.sessionTimerService.SetShowTimer(true);
        this.sessionTimerService.restartTimer();

        this.router.navigateByUrl('/assist-journey/payment-plans', {
          state: {
            assistTravelPlans: JSON.stringify(response['data']['plan_details']),
          },
        });
      }
    }
    catch(error1) {
      this.spinner.hide();
      console.log(error1);
    }
  }
}
