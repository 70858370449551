<div class="row justify-content-center mt-3  d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <form [formGroup]="bsForm" novalidate>
            <div class="row justify-content-center">
                <div class="col-8 blue_box p-4 text-center">
                    <h3 class="heading">Bank Statement</h3>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-11 white_box p-4">
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-10">
                            <div class="row mt-4">
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="bankName" class="control-label">Bank</label>
                                        <select formControlName="bankName" class="form-select">
                                            <option disabled>Select bankName</option>
                                            <option *ngFor="let web of bankList" [ngValue]="web">
                                                {{web.bankName}}</option>
                                        </select>
                                        <div *ngIf="m.bankName.touched && m.bankName.invalid" class="alert alert-danger mt-1">
                                            <div *ngIf="m.bankName.errors?.required">Name is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label class="control-label">Account type</label>
                                        <select formControlName="bankAccountType" class="form-select">
                                            <option disabled>Select account type</option>
                                            <option *ngFor="let web of accountTypeList" [ngValue]="web">
                                                {{web.Name}}</option>
                                        </select>
                                        <div *ngIf="m.bankAccountType.touched && m.bankAccountType.invalid" class="alert alert-danger mt-1">
                                            <div *ngIf="m.bankAccountType.errors?.required">Please select a valid account type</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label class="control-label">Account Number</label>
                                        <input type="text" formControlName="accountNumber" (input)="checkAccountNumber($event)" class="form-control">
                                    </div>
                                    <div class="error" *ngIf="showAccountError">
                                        <div >Enter Valid Account Number</div>
                                    </div>
                                 </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label class="control-label">Statement password</label>
                                        <input type="text" formControlName="bankPassword" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="file-upload">
                                <div class="image-upload-wrap" style="overflow: hidden; background-color: aliceblue;">
                                    <div #filedrop ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (click)="file.click()" (fileOver)="fileOverBase($event)" [uploader]="uploader" class="upload-drop-zone">
                                        <div class="drag-text" *ngIf="uploader.queue.length === 0">
                                            <h3><i class="fas fa-upload"></i><br />
                                                Drag & drop or select file<br />
                                                <div class="choose_file"> Choose file </div>
                                            </h3>
                                        </div>
                                        <div *ngIf="uploader.queue.length > 0" style="width: 100%;margin: auto;">
                                            <div style="line-height: 150%;margin: 0px;">
                                                <ul style="padding-inline: .5rem; padding: 0.7rem 1.5rem;padding: .5rem;">
                                                    <li *ngFor="let file of uploader.queue" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%; font-size: .9rem; padding-right: 1rem; padding-top: .5rem;">
                                                        <button type="button" class="remove-btn" (click)="removeFile(file, $event)">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                        {{ file.file.name }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <object *ngIf="image" id="myimage" width="100%" [data]='image' class="img_preview" style="width: 105%;margin-left: -.1rem;" (click)="file.click()"></object>
                                        <input #file style="display: none;" type="file" (onFileSelected)="updatePreview()" ng2FileSelect [uploader]="uploader" multiple />
                                    </div>


                                    <!-- <div #filedrop ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (click)="file.click()" (onFileSelected)="updatePreview()" (fileOver)="fileOverBase($event)" [uploader]="uploader" class="upload-drop-zone">
                                        <div class="drag-text" *ngIf="uploader.queue.length == 0">
                                            <h3><i class="fas fa-upload"></i><br />
                                                Drag & drop or select file<br />
                                                <div class="choose_file"> Choose file </div>
                                            </h3>
                                        </div>
                                        <object *ngIf="image" id="myimage" width="100%" [data]='image' class="img_preview"></object>
                                        <input #file style="display: none;" type="file" (onFileSelected)="updatePreview()" ng2FileSelect [uploader]="uploader" multiple />
                                    </div> -->
                                </div>
                            </div>
                            <p class="statementDesc-box">{{lastSixMonthDateMsg}}</p>
                            <p class="uploaddocfooter text-success mt-0"> Please upload the documents only in PDF format</p>
                        </div>
                    </div>
                    <div class="row justify-content-center text-center buttonDiv">
                        <div class="col-12">
                            <button type="button" class="btn btn-warning mt-3" [attr.disabled] = "(!accountTypeList.includes(bsForm.get('bankAccountType')?.value) || showAccountError || (bsForm.get('accountNumber')?.value?.length === 0) || !bankList.includes(bsForm.get('bankName')?.value)) ? true : null" (click)="upload()" *ngIf="uploader.queue.length > 0"> Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-lg-5 col-11">
        <app-assist-left></app-assist-left>
    </div>
    <ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>
</div>