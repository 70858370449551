import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, ExtraOptions, Router, RouterModule, RouterStateSnapshot, Routes, UrlTree } from '@angular/router';
import { lastValueFrom, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountService } from './core/services/account.service';
import { MemoryStorageService } from './core/services/memory-storage.service';
import { AssistSignUpComponent } from './modules/assist-journey/assist-sign-up/assist-sign-up.component';
import { BankStatementComponent } from './modules/assist-journey/bank-statement/bank-statement.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

@Injectable({
  providedIn: 'root',
})
export class AssistJourneyNavigatorService  {
  constructor(
    private accountService: AccountService,
    private readonly router: Router
  ) {}

  navigateBasedOnApplicationStatus(
    url,
    currentStatus,
    currentStatusDesc,
    documentType,
    documentNumber,
    status,
    api,
    docUploadStatus,
    partnerId,
    creditAvailableAmount,
    professionalDetailsRequired,
    professionType,
    applicationSource,
    loanAmount,
    showLA,
    showMandate,
    showDP
  ) {

    console.log(partnerId);

    if(currentStatus){
      MemoryStorageService.setItem("currentStatus", currentStatus);
    }
    
    if(partnerId && partnerId !== 'undefined'){

      if(partnerId.includes('INDCR00236') || partnerId === 'INDCR00236'){
        MemoryStorageService.setItem("consolidatorId", 'recfUzc4w8nJZv6aA');
      }
      lastValueFrom(this.accountService.getAgentDetails(partnerId.toUpperCase()))
      .then((agentDetails: any) => {
        console.log('Agent details:', agentDetails);
        if (agentDetails != null && agentDetails.consolidator != null && agentDetails.consolidator.length > 0) {
          MemoryStorageService.setItem("consolidatorId", agentDetails.consolidator[0] || null);
        }
      })
      .catch(error => {
        console.error('Error fetching agent details:', error);
      });
    }

    // storing professionType to be used on utility bill page
    if(professionType){
      MemoryStorageService.setItem("professionType", professionType);
    }

    const urlTree = this.router.parseUrl(url);
    let queryParams = urlTree.queryParams;

    MemoryStorageService.setItem("availableLimit", creditAvailableAmount);
    MemoryStorageService.setItem('userSource', applicationSource);
    MemoryStorageService.setItem('loanAmount', loanAmount)

    let user = JSON.parse(MemoryStorageService.getItem('user'));
    // Used for LOS Bankstatement upload when professional details not found to open professionaldetails page
    let professionalDetailsUpload = MemoryStorageService.getItem("ProfessionalDetailsUploadPage");
    //
    let query = { ...queryParams }

    if(professionalDetailsUpload === 'true'){
      return [['/assist-journey', 'professional-details'], { queryParams: query }];
    }

    // if (currentStatusDesc === 'NACH Mandate Approved'  && (+user['collectionAmount'] < 15000)) 
    // {
    //   return [['/assist-journey', 'payment-gateway'], { queryParams: query }];
    // }
    else if (currentStatusDesc === 'NACH Mandate Approved') 
    {
      // query = { status: 'success', ...queryParams }
      return environment.production && !showDP ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey','payment-gateway'], { queryParams: query } ];
    }
    else if(currentStatusDesc === 'NACH Mandate Created' && query != null && query.hasOwnProperty('source') && query.source.includes('digio')) 
    {
      let counter = parseInt(localStorage.getItem('nachDigioCounter')) || 0;
      if(query.status === 'success'){
        query = { status: 'success', ...queryParams }
        return environment.production && !showDP ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey','payment-gateway'], { queryParams: query } ];
      }
      else if (counter < 3 && query.status !== 'success'){
        query = { ...queryParams }
        return [['/assist-journey', 'nach-mandate'], { queryParams: query }];
      }
      else{
        query = { ...queryParams }
        return [['/assist-journey', 'assist-underwrite'], { queryParams: query }];
      }
    }
    else if(currentStatusDesc === 'NACH Mandate Created'){
      return environment.production && !showMandate ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey', 'nach-mandate'], { queryParams: query }];
    }
    else if (currentStatusDesc === 'NACH Mandate Rejected')
    {
      return [['/assist-journey', 'assist-underwrite'], { queryParams: query }];
    }
    else if (currentStatusDesc === 'Down Payment Link Created') 
    {
      return environment.production && !showDP ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey', 'payment-gateway'], { queryParams: query }];
    }
    else if (currentStatusDesc === 'Down Payment Success') 
    {
      query = { status: 'success', ...queryParams }
      return [['/assist-journey','assist-success'], { queryParams: query } ];
    }
    // else if (currentStatusDesc === 'Down Payment Success' && (+user['collectionAmount'] >= 15000)) 
    // {
    //   return [['/assist-journey', 'assist-success'], { queryParams: query }];
    // }
    else if (currentStatusDesc === 'Down Payment Failed') 
    {
      query = { ...queryParams }
      return [['/assist-journey', 'assist-underwrite'], { queryParams: query }];
    }
    else if (currentStatusDesc === 'Loan Agreement Created') 
    {
      // query = { status: 'success', ...queryParams }
      return [['/assist-journey','loan-agreement'], { queryParams: query } ];
    }
    else if (currentStatusDesc === 'Loan Agreement Approved') 
    {
      // query = { status: 'success', ...queryParams }
      return environment.production && !showMandate ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey', 'nach-mandate'], { queryParams: query }];
    }
    // else if (currentStatusDesc === 'Loan Agreement Approved' && (+user['collectionAmount'] >= 15000)) 
    // {
    //   query = { ...queryParams }
    //   return [['/assist-journey', 'payment-gateway'], { queryParams: query }];
    // }
    else if (currentStatusDesc === 'Loan Agreement Rejected') 
      {
        // query = { ...queryParams }
        return [['/assist-journey', 'assist-underwrite'], { queryParams: query }];
      }

    // go to utility bill upload
    else if((currentStatus === 'AUTO_EMPLOYMENT_VERIFICATION_DENIED' || currentStatus === 'DEVIATION' ) && currentStatusDesc !== 'Utility Bill Uploaded' && currentStatusDesc !== 'ITR Uploaded')
    {
        query = { ...queryParams }
        return [['/assist-journey', 'assist-current-address-proof'], { queryParams: query }];
    }
    else if (currentStatusDesc == 'Utility Bill Uploaded'){
      if(professionType === 'Self Employed' && +loanAmount > 150000)
      {
        query = { ...queryParams }
        return [['/assist-journey', 'assist-income-proof'], { queryParams: query }];
      }
      else if (currentStatus == 'AUTO_EMPLOYMENT_VERIFICATION_DENIED'|| currentStatus == 'DEVIATION')
      {
        query = { ...queryParams }
        return [['/assist-journey', 'assist-underwrite'], { queryParams: query }];
      }
      else {
        query = { ...queryParams }
        return environment.production && !showLA ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey', 'loan-agreement'], { queryParams: query }];
      }
    }
    else if (currentStatusDesc == 'ITR Uploaded'){
      if(currentStatus == 'AUTO_EMPLOYMENT_VERIFICATION_DENIED' || currentStatus == 'DEVIATION' )
      {
        query = { ...queryParams }
        return [['/assist-journey', 'assist-underwrite'], { queryParams: query }];
      }
      else {
        query = { ...queryParams }
        return environment.production && !showLA ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey', 'loan-agreement'], { queryParams: query }];
      }
  }
    else if (
      currentStatus === 'ALL_KYC_DOCS_UPLOADED' &&
      currentStatusDesc === 'Downpayment paid'
    ) {
      return [['/assist-journey', 'assist-success'], { queryParams: query }];
    }
    else if (currentStatusDesc === 'SALARY NOT IDENTIFIED' || currentStatusDesc === 'Salary not found') {
      return [['/assist-journey', 'salary-upload'], { queryParams: query }];
    } 
    else if (currentStatus === 'ALL_KYC_DOCS_UPLOADED' && currentStatusDesc === 'Session timed out') {
      if (user['docs'][0]['bankstatement']) {
        if(professionalDetailsRequired){
          return [['/assist-journey', 'professional-details'], { queryParams: query }];
        }
        else{
          return [['/assist-journey', 'bank-statement'], { queryParams: query }];
        }
      } else {
        return environment.production && !showLA ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey', 'loan-agreement'], { queryParams: query }];
      }
    } 
    else if (currentStatusDesc === 'Session timed out') {
      return [['/assist-journey', 'session-expired'], { queryParams: query }];
    } 
    else if (
      (currentStatus == 'AUTO_APPROVED' &&
        currentStatusDesc == 'Auto Approved') ||
      (currentStatus == 'FINAL_APPROVED_MIHURU' &&
        currentStatusDesc == 'Final Approved Mihuru' &&
        user['docs'][0]['bankstatement'])
    ) {
      return [['/assist-journey', 'assist-payment-plan'], { queryParams: query }];
    } else if (
      (currentStatus == 'FINAL_APPROVED_MIHURU' &&
        currentStatusDesc.startsWith('New Plan Selected')) || 
        (currentStatus == 'AUTO_APPROVED' &&
        currentStatusDesc.startsWith('New Plan Selected'))
    ) {
      return [['/assist-journey', 'assist-current-address-proof'], { queryParams: query }];
    }else if (
      (currentStatus == 'ALL_KYC_DOCS_UPLOADED' ||
        currentStatus == 'AUTO_APPROVED' ||
        currentStatus == 'FINAL_APPROVED_MIHURU') &&
      currentStatusDesc.startsWith('IFSC/MICR is not NACH valid')
    ) {
      return environment.production && !showLA ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey', 'loan-agreement'], { queryParams: query }];
    } else if (currentStatus == 'IN_PRINCIPAL_APPROVED') {
      if (
        currentStatusDesc == 'In Principal Approved' ||
        currentStatusDesc == 'STP approved'
      ) {
        return [['/assist-journey', 'identity-proof'], { queryParams: query }];
      } else {
        //what could it be?
      }
    }
    if (
      currentStatus == 'PARTIAL_KYC_DOCS_UPLOADED' &&
      currentStatusDesc == 'Partial Kyc Docs Uploaded'
    ) {
      return [['/assist-journey', 'address-proof'], { queryParams: query }];
    } else if (
      currentStatus == 'PARTIAL_KYC_DOCS_UPLOADED' &&
      docUploadStatus == false &&
      currentStatusDesc != 'ID OCR Success' &&
      currentStatusDesc != 'ID OCR Failed - 1' &&
      currentStatusDesc != 'ID OCR Failed'
    ) {
      return [['/assist-journey', 'identity-proof'], { queryParams: query }];
    } else if (currentStatus == 'PARTIAL_KYC_DOCS_UPLOADED') {
      if (currentStatusDesc == 'ID OCR Success') {
        //move to aadhar upload - ocr
        return [['/assist-journey', 'identity-proof'], { queryParams: query }];
      } else if (currentStatusDesc == 'ID OCR Failed - 1') {
        //stay on same page pan upload
        return [['/assist-journey', 'identity-proof'], { queryParams: query }];
      } else if (currentStatusDesc == 'ID OCR Failed') {
        //stay on same page pan upload
        return [['/pleasewait', ''], { queryParams: query }];
      } else {
        //pan verification failed
        //return ['/assist-journey', 'identity-proof-verification'];
        return [['/assist-journey', 'address-proof'], { queryParams: query }]; // IDEALLY currentStatus == 'PARTIAL_KYC_DOCS_UPLOADED' && currentStatusDesc == 'Partial Kyc Docs Uploaded'
      }
    } else if (currentStatus === 'AUTO_ID_VERIFICATION_DENIED' || currentStatus === 'AUTO_ADDRESS_VERIFICATION_DENIED') {
      //&& currentStatusDesc.ToString().search("PAN details fetched from OCR doesn't match with details confirmed by user")
      return [['/assist-journey', 'sorry'], { queryParams: query }];
    } else if (
      currentStatus == 'ALL_KYC_DOCS_UPLOADED' &&
      currentStatusDesc == 'All Kyc Docs Uploaded'
    ) {
      //if bankstatement required only then display
      if (user['docs'][0]['bankstatement']) {
        if(professionalDetailsRequired){
          return [['/assist-journey', 'professional-details'], { queryParams: query }];
        }
        else{
          return [['/assist-journey', 'bank-statement'], { queryParams: query }];
        }
      } else {
        return environment.production && !showLA ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey', 'loan-agreement'], { queryParams: query }];
      }
    } else if (
      currentStatus == 'ALL_KYC_DOCS_UPLOADED' &&
      currentStatusDesc == 'Aadhaar Initiate OTP Sent'
    ) {
      // if (documentType == 'address proof' && status == 'Success' && api == 'VERIFY') {
      //   return ['/assist-journey', 'aadhaar-verification'];
      // }
      if (
        documentType == 'address proof' &&
        status == 'Success' &&
        api == 'VERIFY_AADHAAR_OTP'
      ) {
        if (user['docs'][0]['bankstatement']) {
          if(professionalDetailsRequired){
            return [['/assist-journey', 'professional-details'], { queryParams: query }];
          }
          else{
            return [['/assist-journey', 'bank-statement'], { queryParams: query }];
          }
        } else {
          return environment.production && !showLA ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey', 'loan-agreement'], { queryParams: query }];
        }
        //next decide on if bank statement or nach
        // var showBS = false;
        // if (showBS) {
        //   return ['/assist-journey', 'bank-statement'];
        // } else {
        //   return ['/assist-journey', 'nach-mandate'];
        // }
      }
    }
    // else if (currentStatus == 'ALL_KYC_DOCS_UPLOADED' && currentStatusDesc == 'All Kyc Docs Uploaded') {
    //   //check if aadhaar verification was successful?
    //   if (documentType == 'address proof' && status == 'Success' && api == 'VERIFY') {
    //     return ['/assist-journey', 'aadhaar-verification'];
    //   }
    //   if (documentType == 'address proof' && status == 'Success' && api == 'VERIFY_AADHAAR_OTP') {
    //     //next load professional details
    //     return ['/assist-journey', 'professional-details'];
    //     //next decide on if bank statement or nach
    //     // var showBS = false;
    //     // if (showBS) {
    //     //   return ['/assist-journey', 'bank-statement'];
    //     // } else {
    //     //   return ['/assist-journey', 'nach-mandate'];
    //     // }
    //   }
    // }
    else if (
      currentStatus == 'ALL_KYC_DOCS_UPLOADED' &&
      docUploadStatus == false &&
      currentStatusDesc != 'Address OCR Failed' &&
      currentStatusDesc != 'Address OCR Failed - 1'
    ) {
      return [['/assist-journey', 'address-proof'], { queryParams: query }];
    } else if (currentStatus == 'ALL_KYC_DOCS_UPLOADED') {
      if (currentStatusDesc == 'Address OCR Success') {
        //stay on aadhar upload - ocr
        return [['/assist-journey', 'address-proof'], { queryParams: query }];
      } else if (currentStatusDesc == 'Address OCR Failed') {
        //stay on aadhar upload - ocr
        return [['/pleasewait', ''], { queryParams: query }];
      } else if (currentStatusDesc == 'Address OCR Failed - 1') {
        //stay on aadhar upload - ocr
        return [['/assist-journey', 'address-proof'], { queryParams: query }];
      } else if (
        currentStatusDesc.startsWith('Self employed') ||
        currentStatusDesc == 'Professional details updated'
      ) {
        //professional details check
        if (user['docs'][0]['bankstatement']) {
          return [['/assist-journey', 'bank-statement'], { queryParams: query }];
        } else {
          return environment.production && !showLA ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey', 'loan-agreement'], { queryParams: query }];
        }
      } else if (currentStatusDesc == 'NACH Mandate Created') {
        return [url.split('/'), { queryParams: query }];

      }
    } else if (currentStatus == 'Success_ESign_Update_Status') {
      // if(currentStatusDesc=='Success Esign update status'){
      return [url.split('/'), { queryParams: query }];
      //return ['/assist-journey', 'nach-mandate']
      // }
    } else if (currentStatus == 'Fail_ENach_API') {
      // if(currentStatusDesc=='Success Esign update status'){
      return [['/pleasewait', ''], { queryParams: query }];
      //return ['/assist-journey', 'nach-mandate']
      // }
    } else if (
      currentStatus == 'IN_PRINCIPAL_DENIED' ||
      currentStatus == 'AUTO_DENIED' ||
      currentStatus == 'FINAL_DENIED_MIHURU' ||
      currentStatus == 'DEMOGRAPHIC_DENIED' ||
      currentStatus == 'L2_DENIED'
    ) {
      return [['/sorry', ''], { queryParams: query }];
    } else if (currentStatus == 'BANK_STATEMENT_UPLOADED') {
      // If status is BANK STATEMENT UPLOADED this should go to PLEASE WAIT page
      if (currentStatusDesc === 'Bank Statement Uploaded') {
        //20240517:Prajpk
        //this means all good, bank statement is latest 6 months, not fraud, salary identified
        //if prof type self employed then show underwrite page, cause anyways self employed stands no chance
        //return['/assist-journey','app-assist-underwrite']
        //else if salaried then nach

        return environment.production && !showLA ? [['/assist-journey', 'team-callback'], { queryParams: query }] : [['/assist-journey', 'loan-agreement'], { queryParams: query }];
        
      } 
      else if(currentStatusDesc == 'SALARY NOT IDENTIFIED'){
        return [['/assist-journey', 'salary-upload'], { queryParams: query }];
      }
      else if (currentStatusDesc != 'Bank Statement Uploaded') {
        //20240517:Prajpk
        //this means bullshit statement
        //if salaried then ask for salary slip
        //if self employed then show underwrite page
        if(professionalDetailsRequired){
          return [['/assist-journey', 'professional-details'], { queryParams: query }];
        }
        else{
          return [['/assist-journey', 'bank-statement'], { queryParams: query }];
        }
      }
      return [['/pleasewait', ''], { queryParams: query }]; // ADDED ON 27-OCT-2023 : User uploads bank statement that is read by Finbit and API failes - doc should be saved in Azure and user documents table, user application status should change to BANK_STATEMENT_UPLOADED in DB and user should be shown a please wait message / contact team Mihuru message
    } else if (
      currentStatus == 'EMPLOYMENT_PROOF_UPLOADED' &&
      (currentStatusDesc == 'Finbit - Statement Parse Status Error - 1' ||
        currentStatusDesc == 'Finbit - Statement Parse Status Error - 2' ||
        currentStatusDesc == 'Finbit - Statement Parse Status Error')
    ) {
      return [['/assist-journey', 'bank-statement'], { queryParams: query }];
    } else if (
      currentStatus == 'EMPLOYMENT_PROOF_UPLOADED' &&
      currentStatusDesc == 'Bank Stmt Verification Failed'
    ) {
      return [['/sorry', ''], { queryParams: query }];
    } else if (currentStatus == 'EMPLOYMENT_PROOF_UPLOADED') {
      return [['/assist-journey', 'bank-statement'], { queryParams: query }];
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> {
    const nextUrl = childRoute['_routerState'].url;
    const validPaths = [
      '/assist-journey/docupload',
      '/assist-journey/sign-up',
      '/assist-journey/submit-otp',
      '/assist-journey/payment-plans',
      '/assist-journey/confirm-details',
      '/assist-journey/sorry',
      '/assist-journey/assist-success',
      '/assist-journey/session-expired',
      '/assist-journey/team-callback',
      '/assist-journey/assist-underwrite',
      '/assist-journey/assist-processing',
      '/assist-journey/payu-redirect'
      // '/assist-journey/assist-current-address-proof',
      // '/assist-journey/assist-income-proof',
    ];

    // Regular expression to check for the bank statement to upload from LOS path 
    const isBankStatementPath = /^\/\d+\/bank-statement$/.test(nextUrl);

    if (
      validPaths.some(path => nextUrl.startsWith(path)) ||
      isBankStatementPath
    ) {
      return true;
    }
    // TODO: rename later
    const kycStatus = {
      PARTIAL_KYC_DOCS_UPLOADED: 'identity proof',
      ALL_KYC_DOCS_UPLOADED: 'address proof',
    };
    //get latest document verification status
    return this.accountService.getCurrentApplicationStatusDesc().pipe(
      switchMap((currentApplicationStatus) =>
        this.accountService
          .getLatestDocument(kycStatus[currentApplicationStatus.currentStatus])
          .pipe(
            map((latestDocument) => ({
              currentApplicationStatus,
              latestDocument,
            }))
          )
      ),
      map(({ currentApplicationStatus, latestDocument }) => {
        const { currentStatus, currentStatusDesc, docUploadStatus, creditAvailableAmount, professionalDetailsRequired, partnerId, professionType, applicationSource, loanAmount, showLA, showMandate, showDP } =
          currentApplicationStatus;
        const { documentType, documentNumber, status, api } = latestDocument;
        // console.log(nextUrl);
        // console.log(currentStatus);
        // console.log(currentStatusDesc);
        // console.log(documentType);
        // console.log(documentNumber);
        // console.log(status);
        // console.log(api);
        // console.log(docUploadStatus);
        const [routeToNavigate, additionalQueryParams ] = this.navigateBasedOnApplicationStatus(
          nextUrl,
          currentStatus,
          currentStatusDesc,
          documentType,
          documentNumber,
          status,
          api,
          docUploadStatus,
          partnerId,
          creditAvailableAmount,
          professionalDetailsRequired,
          professionType,
          applicationSource,
          loanAmount,
          showLA,
          showMandate,
          showDP
        )!;

        const urlTree = this.router.parseUrl(nextUrl);
        const queryParams = urlTree.queryParams;
        const targetUrlTree = this.router.createUrlTree(routeToNavigate, additionalQueryParams );
        const targetUrl = this.router.serializeUrl(targetUrlTree);
        const target = removeQueryString(targetUrl);
        const sourceUrl = removeQueryString(nextUrl)
        if (nextUrl === routeToNavigate.join('/') || sourceUrl === target) {
          return true;
        }
        return this.router.parseUrl(targetUrl)        
      })
    );
  }
}

function removeQueryString(url) {
  if (url.includes('?')) {
    url = url.split('?')[0];
  }
  if(url.includes(';')) {
    return url.split(';')[0];
  }
  return url;
}

const routes: Routes = [
  {
    path: '',
    component: AssistSignUpComponent,
  },
  {
    path: 'assist-journey', 
    loadChildren: () => import('./modules/assist-journey/assist-journey.module').then(m => m.AssistJourneyModule), 
    canActivateChild: [AssistJourneyNavigatorService],
  },
  {
    path: ':applicationId/bank-statement', component: BankStatementComponent
  },
  {
    path: '**',
    redirectTo: 'assist-journey/sign-up',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // anchorScrolling: 'enabled',
      // scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
