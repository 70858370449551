<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center mt-4">
            <div class="col-8 blue_box p-4 text-center">
                <h3 class="heading">AutoPay - Payment</h3>
            </div>
        </div>
        <div class="row justify-content-center mt-4">
            <div class="col-11 white_box p-4">
                <div class="row mt-1 justify-content-center">
                    <div class="col-10 text-center">
                        <img src="../../assets/images/assist-payment-processing.png" class="img" />
                        <h4 class="mt-4">Your payment is processing, please wait!</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-11">
        <app-assist-left></app-assist-left>
    </div>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>