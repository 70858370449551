import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountService } from '../services/account.service';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  skipURLs = [
    'register',
    'login',
    'resendotp',
    'polltruecallercheck',
    'verifyotp',
    'flightairportlist',
    'PartnerLogs',
    'getOurTeamOrPresence',
    'getTestimonialMedia',
    'airtable',
    'Otp',
    'CoreFactor',
    'sethotelsearch',
    'setflightsearch',
    'file.io',
    'travelplan',
    'ifsc.razorpay',
    'assistmodule/login/apiuser',
    'assistmodule/v1/signup',
    'assistmodule/v2/signup',
    'assistmodule/v1/submitotp',
    'assistmodule/v2/submitotp', 
    'assistmodule/v1/confirmpersonaldetails',
     //'PaymentGateway/Razorpay/GenerateOrder',
    'getpartnerlogo',
    'assistmodule/resendotp',
    'Menedo/getbanklist',
    //'menedo/checkmandate',
    //'menedo/updatemandatestatus',
    //'menedo/fetchmendatestatus'
    'assistmodule/UpdateAlternetNumber',
  ];

  constructor(private accountService: AccountService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var currentUser!: User;

    var urlfronSkipURLs = this.skipURLs.filter(x => {
      return request.url.includes(x) ? true : false;
    });

    if (urlfronSkipURLs.length <= 0) {

      if (this.accountService.currentUser$ != null) {
        this.accountService.currentUser$.subscribe(user => {
          currentUser = (user != null ? user : null)!;
        });
      }
      if (
        request.url.includes('assistmodule/v1/createUserProfile') ||
        request.url.includes('ProposedEMIPlans') ||
        request.url.includes('getcurrentapplicationstatusdesc') ||
        request.url.includes('GetLatestDocument') ||
        request.url.includes('assistmodule/uploadbusinessdetails')
        //|| request.url.includes('application/setApplStatusReasonHistories')        
      ) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + currentUser.token,
            'ApiKey': environment.PartnerapiKey,
            'PartnerId': environment.PartnerId
          },
          withCredentials: false,
        });
      } 
      else if(request.url.includes('agent')){
        console.log('agent interceptor');
        request = request.clone({
          setHeaders: {
            'ApiKey': environment.PartnerapiKey,
            'PartnerId': environment.PartnerId
          },
          withCredentials: false,
        });
      }
      else {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + currentUser?.token
          },
          withCredentials: false
        });
        console.log('auth interceptor');
        console.log(request);
      }
    }
    return next.handle(request);
  }
}
