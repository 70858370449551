import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/core/services/account.service';
import { environment } from 'src/environments/environment';
import { SessionTimerService } from '../../../core/services/session-timer.service';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import swal from 'sweetalert2';

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

@Component({
  selector: 'app-salary-upload',
  templateUrl: './salary-upload.component.html',
  styleUrls: ['./salary-upload.component.scss']
})
export class SalaryUploadComponent implements OnInit {
  baseUrl!: string;
  private maxFileCount = 1;
  uploaderOptions: FileUploaderOptions = {
    url: 'https://mihuru.com',
    queueLimit: this.maxFileCount,
    disableMultipart: true,
    formatDataFunctionIsAsync: true,
    formatDataFunction: async (item: FileItem) => {
      return {
        name: item.file.name,
        length: item.file.size,
        contentType: item.file.type,
        date: new Date(),
      };
    },
  };
  uploader = new FileUploader(this.uploaderOptions);
  selectedFiles: string[] = [];
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  constructor(private router: Router, private accountService: AccountService, private sessionTimerService :SessionTimerService, private spinner: NgxSpinnerService ) {
    this.baseUrl = environment.apiUrl + 'assistmodule/uploadbusinessdetails';
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.response = '';
    this.uploader.response.subscribe((res: any) => (this.response = res));
  }

  async upload() {
    const files = this.uploader.queue;
    if (!this.fileValidation(files)) {
      return;
    }

    const fileBase64 = await Promise.all(
      files.map((file) => toBase64(file._file))
    );

    let obj = {
      "occupationType": '-',
      "isSalaryUpload": true,
      "companyType": '-',
      "companyName": '-',
      "professionIdType": '-',
      "professionId": '-',
      "isProprietor": false,
      "isGST": false,
      "files": fileBase64
    }
    this.accountService.uploadBusinessDetails(obj).subscribe(
      (res) => {
        //navigate to next page
        this.router.navigateByUrl('/assist-journey/assist-underwrite');
      }
    )

  }
  

  fileValidation(files: FileItem[]) {
    const sizeExceed = files.filter((file) => file._file.size >= 2048000);
    if (sizeExceed.length) {
      swal.fire('Size exceed');
      return false;
    }
    const invalidFiles = files.filter(
      (file) =>
        !['image/bmp', 'image/jpeg', 'image/png', 'application/pdf'].includes(
          file._file.type
        )
    );
    if (invalidFiles.length) {
      swal.fire('Invalid File Type');
      return false;
    }
    return true;
  }

  onFileSelected() {
    this.updateSelectedFiles();
  }

  removeFile(item: FileItem, event: MouseEvent): void {
    event.stopPropagation();
    if(item && this.uploader.queue.includes(item)){
      this.uploader.removeFromQueue(item);
    }
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  ngOnInit(): void {
    this.spinner.hide();
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      if (this.uploader.queue.length > this.maxFileCount) {
        this.uploader.removeFromQueue(fileItem);
        swal.fire(`You can only upload up to ${this.maxFileCount} files`);
      }
      this.updateSelectedFiles();
    };
  }

  private updateSelectedFiles(): void {
    this.selectedFiles = this.uploader.queue.map((fileItem: FileItem) => fileItem.file.name);
  }
}
