import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, Subscription } from 'rxjs';
import { AccountService } from 'src/app/core/services/account.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { SessionTimerService } from '../../../core/services/session-timer.service';

@Component({
  selector: 'app-assist-loan-agreement',
  templateUrl: './assist-loan-agreement.component.html',
  styleUrls: ['./assist-loan-agreement.component.scss']
})
export class AssistLoanAgreementComponent implements OnInit {

  private loanAgreementUrl: string;
  private loanAgreementId: string;
  private loanagreementWindow: Window | null = null;
  paymentStatusSubscription: Subscription;
  private source: string;
  private UpdateStatus = false;

  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute, private accountService: AccountService, private spinner: NgxSpinnerService, private sessionTimerService :SessionTimerService, private userService: UserService) {}

  ngOnInit(): void {

    this.spinner.hide();
   // if(!environment.production){
      //this.spinner.show();
      document.getElementById('popup-pre-loan-agreement')!.style.display = 'block';
      document.getElementById('overlay-pre-loan-agreement')!.style.display = 'block';

      try
      {
        // this.accountService.updateNACHApplicationStatus(docId, 2).subscribe((response: any) => {

        // });
        this.accountService.generateLoanAgreementURL('success', 'null', '').subscribe(
        (res) => {
          document.getElementById('popup-pre-loan-agreement')!.style.display = 'none';
          document.getElementById('overlay-pre-loan-agreement')!.style.display = 'none';
            if(res['data'] && res['data'].hasOwnProperty('response_msg') && res['data']['response_msg'].hasOwnProperty('url')){
              this.openWindow(res['data']['response_msg']['url'], res['data']['response_msg']['id'] );
            }
        });;
      }
      catch(err)
      {
        //this.spinner.hide();
        document.getElementById('popup-pre-loan-agreement')!.style.display = 'none';
        document.getElementById('overlay-pre-loan-agreement')!.style.display = 'none';
        console.log(err);
        swal.fire("Something went wrong!");
      }
   // }


    //this.sessionTimerService.resumeTimer();
    // let status = '';
    // this.route.queryParamMap.subscribe(params => {
    //   status = params.get('status');
    //   this.source = params.get('source');
    //   if(status === 'failure' || status === 'cancel'){
    //     // TODO update failed status on Table
    //     this.http.post(environment.apiUrl + 'ENachAPI/enachWebHookUpdate', { status: status }).subscribe(
    //     (res) => {
    //       console.log(res); 
    //     });
    //     let docId = params.get('digio_doc_id');
    //     this.accountService.updateNACHApplicationStatus(docId, 1).toPromise();
    //     return 
    //   }
    //   else if(status === 'success'){
    //     //TODO update success status on Table
        
    //   }
    // });

    // if(status === 'failure' || status === 'cancel')
    // {
    //   this.router.navigateByUrl('/assist-journey/nach-mandate');
    //   return;
    // }
  }

  async openWindow(url: string, loanagreementid: string) {

    this.loanAgreementId = loanagreementid;
    this.startPaymentStatusCheck();
    document.getElementById('popup-loan-agreement')!.style.display = 'block';
    document.getElementById('overlay-loan-agreement')!.style.display = 'block';

    this.loanAgreementUrl = url;

    this.loanagreementWindow = window.open(url, '_blank');
    
    await this.waitForWindowToClose();

    try {
      this.accountService.fetchLoadAgreementStatus(this.loanAgreementId).subscribe((response: any) => {
        if (response['data']) {
          const res = JSON.parse(response['data']);
          if(res.hasOwnProperty('agreement_status') && res.agreement_status.toLowerCase() == 'completed'){

            if(this.paymentStatusSubscription){
              this.paymentStatusSubscription.unsubscribe();
            }

            if(this.UpdateStatus ){
              return;
            }
    
            
            this.accountService.updateLoanAgreementStatus('success', "").subscribe((response) => {      
              
              this.UpdateStatus = true;
              MemoryStorageService.setItem("navigateURL", response['data'])
              return this.router.navigateByUrl('/assist-journey/nach-mandate');
              
            });
          }
          else if(res.hasOwnProperty('agreement_status') && ( res.agreement_status.toLowerCase() == 'failed' || res.agreement_status.toLowerCase() == 'expired'))
          {
            if(this.paymentStatusSubscription){
              this.paymentStatusSubscription.unsubscribe();
            }

            if(this.UpdateStatus ){
              return;
            }    

            this.accountService.updateLoanAgreementStatus('failed', "").subscribe((response) => {

              this.UpdateStatus = true;
              /*** Redirect if Loan Agreement Failed for Sterling Holidays ***/
              if(environment.sterlingHolidaysConsolidator.includes(MemoryStorageService.getItem("consolidatorId")))
              {
                const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "LoanAgreementFailed" };
                this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                return;
              }
              /*******************/

              return this.router.navigateByUrl('/assist-journey/assist-underwrite?redirect='+ response['data']);
            });
            
          }
          else {
            if(this.paymentStatusSubscription){
              this.paymentStatusSubscription.unsubscribe();
            }

            if(this.UpdateStatus ){
              return;
            }    

            this.accountService.updateLoanAgreementStatus('failed', "").subscribe((response) => {
              
            this.UpdateStatus = true;
              /*** Redirect if Loan Agreement Failed for Sterling Holidays ***/
              if(environment.sterlingHolidaysConsolidator.includes(MemoryStorageService.getItem("consolidatorId")))
              {
                const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "LoanAgreementFailed" };
                this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                return;
              }
              /*******************/
              
              return this.router.navigateByUrl('/assist-journey/assist-underwrite?redirect='+ response['data'])
            });
          }
        }
      });
    } catch (error) {
      console.error('Error fetching NACH status:', error);
      //swal.fire("An error occurred while checking the Downpayment status.");
    }
  }
  
  // Function to check if the window is closed
  private waitForWindowToClose(): Promise<void> {
    return new Promise((resolve) => {
      const intervalId = window.setInterval(() => {
        if (this.loanagreementWindow?.closed) {
          clearInterval(intervalId);
          document.getElementById('popup-loan-agreement')!.style.display = 'none';
          document.getElementById('overlay-loan-agreement')!.style.display = 'none';
          resolve();
        }
      }, 1000);
    });
  }
  
  OpenWindowByUser(){
    if (!this.loanagreementWindow){
      this.loanagreementWindow = window.open(this.loanAgreementUrl, '_blank');
    }
  }

  
  startPaymentStatusCheck(): void {
    if (this.paymentStatusSubscription) {
      this.paymentStatusSubscription.unsubscribe();
    }

    this.paymentStatusSubscription = interval(10000).subscribe(async () => {

      this.accountService.fetchLoadAgreementStatus(this.loanAgreementId).subscribe((response: any) => {
        if (response['data']) {
          const res = JSON.parse(response['data']);
          if(res.hasOwnProperty('agreement_status') && res.agreement_status.toLowerCase() == 'completed'){
            if(this.paymentStatusSubscription){
              this.paymentStatusSubscription.unsubscribe();
            }
            this.loanagreementWindow?.close();
            if(this.UpdateStatus){
              return;
            }    
            this.accountService.updateLoanAgreementStatus('success', "").subscribe((response) => {
              this.UpdateStatus = true;
              return this.router.navigateByUrl('/assist-journey/nach-mandate');

            });

          }
          else if(res.hasOwnProperty('agreement_status') && ( res.agreement_status.toLowerCase() == 'failed' || res.agreement_status.toLowerCase() == 'expired'))
          {
            if(this.paymentStatusSubscription){
              this.paymentStatusSubscription.unsubscribe();
            }
            this.loanagreementWindow?.close();
            if(this.UpdateStatus){
              return;
            }    
            this.accountService.updateLoanAgreementStatus('failed', "").subscribe((response) => {
              this.UpdateStatus = true;
              /*** Redirect if Loan Agreement Failed for Sterling Holidays ***/
              if(environment.sterlingHolidaysConsolidator.includes(MemoryStorageService.getItem("consolidatorId")))
              {
                const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "LoanAgreementFailed" };
                this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
                return;
              }
              /*******************/
              
              return this.router.navigateByUrl('/assist-journey/assist-underwrite?redirect='+ response['data'])
            });
            
          }
          // else {

          //   if(this.paymentStatusSubscription){
          //     this.paymentStatusSubscription.unsubscribe();
          //   }
          //   this.loanagreementWindow?.close();
          //   this.accountService.updateLoanAgreementStatus('failed', "").subscribe((response) => {
          //     if(this.source && this.source != 'undefined' && this.source == 'api' ){
          //       /*** Redirect if Loan Agreement Failed for Sterling Holidays ***/
          //       if(environment.sterlingHolidaysConsolidator === MemoryStorageService.getItem("consolidatorId"))
          //       {
          //         const data = { partnerTransactionId: MemoryStorageService.getItem("partnerTransactionId"), status: "LoanAgreementFailed" };
          //         this.userService.redirectWithPost(environment.sterlingHolidaysUrl, data);
          //         return;
          //       }
          //       /*******************/
          //     }
          //     return this.router.navigateByUrl('/assist-journey/assist-underwrite?redirect='+ response['data'])
          //   })
          // }
        }
      });
      // const response = await this.accountService.FetchDownPaymentStatus(this.merchantTxnId).toPromise();
      // if (response['data']) {
      //   const result = JSON.parse(response['data']);
      //   if (result.hasOwnProperty('data') && result.data[0].hasOwnProperty('status') && result.data[0].status === 'Paid') {
      //     // Update Status to Paid
      //     if (this.paymentStatusSubscription) {
      //       this.paymentStatusSubscription.unsubscribe();
      //     }
          
      //     this.downPaymentStatusRequest.type = 'Update';
      //     this.downPaymentStatusRequest.merchantTxnId = this.merchantTxnId;
      //     this.downPaymentStatusRequest.status = 'Paid';
      //     this.accountService.checkUpdateDownpayment(this.downPaymentStatusRequest).subscribe((response) => {});

      //     this.router.navigateByUrl('/assist-journey/assist-success');
      //   }
      // }
    });
  }

  ngOnDestroy(): void {
    if (this.paymentStatusSubscription) {
      this.paymentStatusSubscription.unsubscribe();
    }
  }

}
